
import { isEmpty } from "lodash";
export default {
	name: "CardTextarea",
	model: {
		prop: "model_data",
		event: "input"
	},
	props: {
		model_data: {},
		rules: {
			type: Array,
			default: function() {
				return [];
			}
		},
		hint: {
			type: String,
			default: ""
		},
		reference: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: [String, Object],
			default: ""
		},
		customClass: {
			type: String,
			default: "label-fixed static"
		},
		counter: {
			type: [Number, Boolean],
			default: 255
		},
		persistent_hint: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		error: {
			type: Boolean,
			default: false
		},
		messages: {
			type: String,
			default: ""
		},
		required: {
			type: Boolean,
			default: false
		},
		rows: {
			type: Number,
			default: 3
		},
		auto_grow: {
			type: Boolean,
			default: true
		},
		append_icon: {
			type: String,
			default: ""
		},
		append_outer: {
			type: String,
			default: ""
		},
		btn_text: {
			type: String,
			default: ""
		},
		no_resize: {
			type: Boolean,
			default: false
		},
		disabled:{
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2 static",
		},
		single_line: {
			type: Boolean,
			default: false,
		},
	},
	data: function() {
		return {};
	},
	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			}
		},
		getCustomClass() {
			return `${this.customClass} ${
				this.disabled || this.readonly ? "disabled" : ""
			}`;
		},
	},
	methods: {
		isEmpty(data: any) {
			return isEmpty(data);
		},
		clickAppendOuter(params: any) {
			this.$emit("click-append-outer", params);
		},
		clickAppend() {
			this.$emit("click-append", this.modelData);
		}
	},
	watch: {}
};
